.App {
    background-color: #000;
    text-align: center;
}

.App-logo {
    height: 75px;
    pointer-events: none;
    filter: drop-shadow(0 0 16px #8EF61E);
    animation: pulsate-rotate 1.5s infinite alternate;
}

.logo-inside {
    height: 40px;
    pointer-events: none;
    filter: drop-shadow(0 0 16px #8EF61E);
    animation: pulsate-svg 1.5s infinite alternate;
}

.logo-neon {
    animation: pulsate0 1.5s infinite alternate;
}

@media (prefers-reduced-motion: no-preference) {
}

.header-one {
    background-image: url("./../public/background 5.png");
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;

    min-height: 200vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-header {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.coming-soon {
    animation: pulsate 1.5s infinite alternate;
}

@keyframes App-logo-spin {
    0% {
        transform: rotate(-1deg);
    }
    49% {
        transform: rotate(1deg);
    }
    51% {
        transform: rotate(1deg);
    }
    100% {
        transform: rotate(-1deg);
    }
}

.neonText {
    position: absolute;
    top: 10%;
    color: #fff;
    text-shadow: 0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #8EF61E,
    0 0 82px #8EF61E,
    0 0 92px #8EF61E,
    0 0 102px #8EF61E,
    0 0 151px #8EF61E;
}

.neon-body {
    font-size: 18px;
    font-family: monospace, sans-serif;
    background-color: #010a01;
    display: flex;
    justify-content: center;
    align-items: center;
}

h1, h2 {
    text-align: center;
    font-weight: 400;
}

h1.neonText {
    transform: perspective(800px) rotateY(25deg) scale(0.9) rotateX(10deg);
    filter: blur(2px);
    transition: 0.6s ease all;

    &:hover {
        transform: perspective(800px) rotateY(-15deg) translateY(-50px) rotateX(10deg) scale(1);
        filter: blur(0);
        opacity: 1;
    }

    font-size: 3.2rem;
    animation: pulsate 1.5s infinite alternate;
    border: 0.2rem solid #fff;
    border-radius: 2rem;
    padding: 0.4em;
    box-shadow: 0 0 .2rem #fff,
    0 0 .2rem #fff,
    0 0 2rem #8EF61E,
    0 0 0.8rem #8EF61E,
    0 0 2.8rem #8EF61E,
    inset 0 0 1.3rem #8EF61E;
}

@keyframes pulsate {

    100% {

        text-shadow: 0 0 4px #fff,
        0 0 11px #fff,
        0 0 19px #fff,
        0 0 40px #8EF61E,
        0 0 80px #8EF61E,
        0 0 90px #8EF61E,
        0 0 100px #8EF61E,
        0 0 150px #8EF61E;

    }

    0% {

        text-shadow: 0 0 2px #fff,
        0 0 4px #fff,
        0 0 6px #fff,
        0 0 10px #8EF61E,
        0 0 25px #8EF61E,
        0 0 35px #8EF61E,
        0 0 40px #8EF61E,
        0 0 50px #8EF61E;

    }
}

@keyframes pulsate-svg {

    100% {
        filter: drop-shadow(0 0 8px #8EF61E);
    }

    88% {
        filter: drop-shadow(0 0 9px #8EF61E);
    }

    76% {
        filter: drop-shadow(0 0 10px #8EF61E);
    }

    64% {
        filter: drop-shadow(0 0 11px #8EF61E);
    }

    52% {
        filter: drop-shadow(0 0 12px #8EF61E);
    }

    40% {
        filter: drop-shadow(0 0 13px #8EF61E);
    }

    28% {
        filter: drop-shadow(0 0 14px #8EF61E);
    }

    16% {
        filter: drop-shadow(0 0 15px #8EF61E);
    }

    0% {
        filter: drop-shadow(0 0 16px #8EF61E);
    }
}

@keyframes pulsate-rotate {

    100% {
        filter: drop-shadow(0 0 5px #8EF61E);
        transform: rotate(-1deg);
    }

    88% {
        filter: drop-shadow(0 0 6px #8EF61E);
    }

    76% {
        filter: drop-shadow(0 0 7px #8EF61E);
    }

    64% {
        filter: drop-shadow(0 0 12px #8EF61E);
        transform: rotate(1deg);
    }

    52% {
        filter: drop-shadow(0 0 14px #8EF61E);
    }

    40% {
        filter: drop-shadow(0 0 16px #8EF61E);
        transform: rotate(1deg);
    }

    28% {
        filter: drop-shadow(0 0 17px #8EF61E);
    }

    16% {
        filter: drop-shadow(0 0 18px #8EF61E);
    }

    0% {
        filter: drop-shadow(0 0 20px #8EF61E);
        transform: rotate(-1deg);
    }
}

.svg-m {
    height: 45px;
    pointer-events: none;
    filter: drop-shadow(0 0 16px #8EF61E);
    animation: pulsate-rotate 1.5s infinite alternate;
}

.footer-one {
    bottom: 0;
    right: 0;
    display: block;
    position: fixed;
    align-items: end;
    justify-content: end;
    font-size: calc(10px + 2vmin);
    color: white;
    opacity: 0.7;
}

.footer {
    display: flex;
    justify-content: end;
    align-items: end;
    justify-items: end;
    height: 50px;
    width: 50px;
}

.footer-near {
    margin-left: 20px;
}

.smallLabels {
    font-size: 0.7rem;
    color: #8EF61E;
    wrap-option: nowrap;
    wrap: nowrap;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
}

.closed {
    display: none;
    transition: 0.5s;
}

.opened {
    display: flex;
    transition: 0.5s;
}

.iconRotate45 {
    transform: rotate(45deg) !important;
    transition: transform 2s;
}
